import React,{ useContext, useEffect, useState }  from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus,faMinus} from "@fortawesome/free-solid-svg-icons";
import CustomerContext from '../../context/customerContext';

const QuestionAnswer = ({faq}) => {
  const { question } = faq
  const { shippingPrice } = useContext(CustomerContext)
  const [open, setOpen] = useState(false)
  const [answerText, setAnswerText] = useState((shippingPrice === 3 && !!faq?.answerOldUser) ? faq?.answerOldUser : faq?.answer)

  useEffect(() => {
    if (shippingPrice === 3 && !!faq?.answerOldUser) {
      setAnswerText(faq?.answerOldUser)
      return
    }
    setAnswerText(faq?.answer)
  }, [shippingPrice])

  const createAnswerMarkup = (answerText) => {
    return { __html: answerText }
  }
  return (
    <div className="faq" key={question} onClick={() => setOpen(!open)}>
      <h5 className="question">{question}</h5>
      {open ? <FontAwesomeIcon icon={faMinus} style={{height: "1em"}} color={"#2b3235"} /> : <FontAwesomeIcon icon={faPlus} style={{height: "1em"}} color={"#2b3235"}/> }
      <p
        className={`answer ${open ? "open": 'closed'}`}
        dangerouslySetInnerHTML={createAnswerMarkup(answerText)}></p>
    </div>
  )
}

export default QuestionAnswer
